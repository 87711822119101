import {BadgeVariant} from "../shared/models";

export const statusColorMap = (status: string): BadgeVariant => {
  return {
    "COMPLETED": 'success',
    "PAID": 'success',
    "PARTIALLY-PAID": 'success',
    "CREATED": 'warning',
    "VERIFIED": 'warning',
    "REJECTED": 'danger',
    "APPROVED": "success",
    "IN_PROGRESS": "warning",
    "FAILED": "danger",
    "SENT_TO_PROVIDER": "warning"
  } [status] as BadgeVariant || 'warning';
}

export const arrayToObjectWithCamelCaseKeys = <T extends Record<string, unknown>>(
  array: T[],
  key: keyof T,
  value: keyof T
): Record<string, unknown> =>{
  return array.reduce((acc, item) => {
    const originalKey = String(item[key]);
    const camelCaseKey = toCamelCase(originalKey);
    acc[camelCaseKey] = item[value];
    return acc;
  }, {} as Record<string, unknown>);
}

export const toCamelCase = (key: string): string =>{
  return key
    .replace(/([-_][a-zA-Z0-9])/g, group =>
      group.toUpperCase().replace('-', '').replace('_', '')
    )
    .replace(/^[A-Z]/, group => group.toLowerCase());
}
