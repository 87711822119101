import { computed, inject } from "@angular/core";
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { firstValueFrom } from "rxjs";

import { getCurrencyMetadata } from "../../helpers/currency.helper";
import { DEFAULT_BALANCES } from "../constants/currency";
import { Balance, User } from "../models/user";
import { AuthService } from "../services/auth.service";
import { PayOutService } from "../services/payOut.service";
import { PermissionService } from "../services/permission.service";
import { UserService } from "../services/user.service";

export interface UserState {
  user: User | null;
  permissions: string[];
  roles: string[];
  permissionsStatus: 'LOADED' | 'LOADING' | 'PENDING'
  isLoading: boolean;
  balances: Balance[];
  userId: string;
  companyId: string;
}

const initialState: UserState = {
  user: {
    id: "",
    uid: "",
    cognitoId: "",
    email: "",
    firstName: "",
    lastName: "",
    businessName: "",
    nit: 1024540803,
    documentType: "",
    country: {
      id: "",
      countryCode: "",
      countryName: ""
    },
    company: {
      id: "",
      businessName: "",
      documentType: "",
      documentId: "",
      city: null,
      state: null,
      address: null,
      postalCode: null,
      country: {
        id: "",
        countryCode: "",
        countryName: ""
      },
    }
  },
  permissions: [],
  roles: [],
  permissionsStatus: 'PENDING',
  isLoading: false,
  balances: DEFAULT_BALANCES,
  userId: '',
  companyId: ''
};

export const UserStore = signalStore(
  { providedIn: "root" },
  withState(initialState),
  withComputed(({balances}) => ({
    balancesWithMetadata: computed(() => balances()
      .map(balance => ({
        ...balance,
        ...getCurrencyMetadata(balance.currency)
      }))
    )
  })),
  withMethods((store) => ({
    hasPermission: (permission: string) => {
      if(store.permissionsStatus() === 'LOADED'){
        return store.permissions().includes(permission);
      } else {
        throw new Error("You're calling hasPermission before loading the permissions")
      }
    },
    loadPermissions: async (
      permissionService = inject(PermissionService),
      userService = inject(UserService)
    ) => {
      if(store.permissionsStatus() === 'PENDING'){
        patchState(store, { permissionsStatus: 'LOADING' })
        const permissions = await firstValueFrom(permissionService.getUserPermissions())
        console.log("PERMISSIONS:", permissions);
        //TODO: move this code to get the user information to the onInit hook before
        const currentUser = await firstValueFrom(userService.getUserData());
        console.log("currentUser:", currentUser);
        if(!currentUser){
          console.error("LOAD_PERMISSIONS: User authenticated not found in db")
        }
        // END CODE TO REMOVE
        patchState(store, {
          permissions,
          userId: currentUser.id,
          companyId: currentUser.company.id,
          user: currentUser
        })
        patchState(store, { permissionsStatus: 'LOADED' })
      }
    },
    loadBalances: async (payoutService = inject(PayOutService)) => {
      const balances = await firstValueFrom(payoutService.getUserBalances());
      if(balances?.length){
        patchState(store, {
          balances
        })
      }
    }
  })),
  withHooks({
    async onInit(
      store,
      authService = inject(AuthService)
    ) {
      try {
        patchState(store, { isLoading: true });
        const roles = await authService.getUserRoles()
        // TODO: implement the service to get the user data here
        patchState(store, {
          roles,
          isLoading: false
        });
      } catch {
        patchState(store, { isLoading: false });
      }
    }
  })
);
