import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponseWithMeta } from "../models/api";
import { PaginationEvent } from "../models/table";
import { NewUserPayload, Role, User } from "../models/user";




@Injectable({
  providedIn: 'root'
})
export class UserService {
  private http = inject(HttpClient);

  getUserData(): Observable<User> {
    return this.http.get<User>(`${environment.supraApi}/v1/user/me`);
  }

  getAllUsers(pagination?: PaginationEvent): Observable<ApiResponseWithMeta<User>> {
    const params = {
      limit: pagination?.pageSize.toString() || '10',
      page: pagination?.pageIndex ? (pagination.pageIndex + 1).toString() : '1',
    };
    return this.http.get<ApiResponseWithMeta<User>>(`${environment.supraApi}/v1/user/company/list`, { params });
  }
 
  getAllRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${environment.supraApi}/v1/role/list`);
  }

  getRoles(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.supraApi}/v1/user/me/roles`);
  }

  createUser(user: NewUserPayload): Observable<User> {
    return this.http.post<User>(`${environment.supraApi}/v1/user/company`, user);
  }

  editUser(userId:string, user: NewUserPayload): Observable<string> {
    return this.http.put<string>(`${environment.supraApi}/v1/user/company/${userId}`, user);
  }
  changeUserStatus (userId: string, status:'active'|'disabled'): Observable<string>{
    return this.http.put<string>(`${environment.supraApi}/v1/user/company/status/${userId}/${status}`, {});
  }
  updateUserRole (userId: string, roleSlug: string): Observable<string> {
    return this.http.put<string>(`${environment.supraApi}/v1/user/company/role/${userId}`, { roleSlug });
  }
  completeOnboarding (): Observable<string> {
    return this.http.post<string>(`${environment.supraApi}/v1/user/complete-onboarding`, {});
  }
}
