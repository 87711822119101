import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {map, Observable, switchMap} from "rxjs";
import {environment} from "../../../environments/environment";
import {Balance} from "../models/user";
import {PaginationEvent} from "../models/table";
import {ApiResponseItemsWithMeta, ApiResponseWithMeta, UnknownObject, ValueAndLabel} from "../models/api";
import {
  Beneficiary,
  BeneficiaryApi,
  BeneficiaryPayload,
  Payment,
  PaymentPayload,
  PaymentStatus,
  TransferFee, TransferFeePayload
} from "../models/transaction";
import {Country} from "../models/country";
import {arrayToObjectWithCamelCaseKeys} from "../../helpers/maps.helper";
import {translateAndSortCountries} from "../../helpers/translation.helper";
import {TranslateService} from "@ngx-translate/core";
import {CurrencyCountry} from "../models/currency";

@Injectable({
  providedIn: 'root'
})
export class PayOutService {

  private http = inject(HttpClient);
  private translateService = inject(TranslateService);

  getUserBalances(): Observable<Balance[]> {
    return this.http.get<Balance[]>(`${environment.supraApi}/v1/user/me/company/balances`);
  }

  getPayments(query : {
    pagination?: PaginationEvent,
    filter?: {
      search?: string,
      status?: PaymentStatus
    }
  }): Observable<ApiResponseWithMeta<Payment>> {
    let params = new HttpParams()
      .set('limit', query.pagination?.pageSize?.toString() || '10')
      .set('page', query.pagination?.pageIndex ? (query.pagination.pageIndex + 1).toString() : '1');

    if (query.filter) {
      Object.entries(query.filter).forEach(([key, value]) => {
        if (value) {
          params = params.set(`filter.${key}`, value.toString());
        }
      });
    }
    return this.http.get<ApiResponseWithMeta<Payment>>(`${environment.supraApi}/v2/payout/payment/user/me`, {params});
  }

  getCountries(): Observable<Country[]>{
    const params = {
      limit: 100,
      page: 1
    }
    return this.http.get<{ countries: Country[] }>(`${environment.supraApi}/v1/flows/payout/countries`, {params})
      .pipe(
        switchMap(response => translateAndSortCountries(response, this.translateService)),
        map(response => response.countries)
      );
  }

  getBeneficiaries(query? : {
    pagination?: PaginationEvent
  }): Observable<ApiResponseWithMeta<Beneficiary>>{
    const params = new HttpParams()
      .set('limit', query?.pagination?.pageSize?.toString() || '1000')
      .set('page', query?.pagination?.pageIndex ? (query.pagination.pageIndex + 1).toString() : '1');

    return this.http.get<ApiResponseItemsWithMeta<BeneficiaryApi>>(`${environment.supraApi}/v2/payout/user/me/beneficiaries`, {params})
      .pipe(
        map((response) => ({
          meta: response.meta,
          data: response.items.map(item => ({
            ...item,
            details: arrayToObjectWithCamelCaseKeys(item.details, 'fieldName', 'value'),
            accounts: item.accounts.map(account => ({
              id: account.id,
              ...arrayToObjectWithCamelCaseKeys(account.details, 'fieldName', 'value'),
            })),
          })),
        }))
      );
  }

  getBeneficiaryById(id: string): Observable<BeneficiaryApi>{
    return this.http.get<BeneficiaryApi>(`${environment.supraApi}/v2/payout/beneficiaries/${id}`)
  }

  createBeneficiary(payload: BeneficiaryPayload): Observable<string>{
    return this.http.post<string>(`${environment.supraApi}/v2/payout/beneficiary/with-account`, payload)
  }

  getPaymentTypes(): Observable<ValueAndLabel[]> {
    return this.http.get<Record<string, string>>(`${environment.supraApi}/v1/payout/payment/types`)
      .pipe(
        map(
          response => Object.entries(response)
            .map(([key, value]) => ({
              value: key,
              label: value
            })
          )
        )
      );
  }

  getCurrenciesByCountry(country: string): Observable<{ currencies: CurrencyCountry[] }> {
    return this.http.get<{ currencies: CurrencyCountry[] }>(`${environment.supraApi}/v1/flows/payout/currencies/country/${country}`)
  }

  getPayoutFees({
      bankAccountId,
      beneficiaryId,
      amount
    }:TransferFeePayload): Observable<TransferFee>{
    return this.http.get<TransferFee>(`${environment.supraApi}/v1/payout/user/me/beneficiary/${beneficiaryId}/bankAccount/${bankAccountId}/calculate-fees`, { params: {amount}})
  }

  createPayment(payload: PaymentPayload): Observable<UnknownObject>{
    console.log(payload);
    const formData = new FormData();
    formData.append('currency', payload.currency);
    formData.append('beneficiaryId', payload.beneficiaryId);
    formData.append('accountId', payload.accountId);
    formData.append('paymentTypeId', payload.paymentTypeId);
    formData.append('operationDetail', payload.operationDetail);
    formData.append('amount', payload.amount.toString());
    formData.append('externalId', "");

    if (payload.receipt && payload.receipt.length > 0) {
      payload.receipt.forEach((file: File) => {
        formData.append(`documents`, file);
      });
    }
    // TODO: review service once it's fixed
    return this.http.post<UnknownObject>(`${environment.supraApi}/v3/payout/payment`, formData)
  }

  getPaymentById(id: string): Observable<Payment>{
    return this.http.get<Payment>(`${environment.supraApi}/v2/payout/payment/${id}`)
  }

  getFields(countryCode: string, currencyCode: string): Observable<{ fields: [] }>{
    return this.http.get<{ fields: []}>(`${environment.supraApi}/v2/fields/beneficiary/country/${countryCode}/currency/${currencyCode}`)
  }

  changePaymentStatus(id: string, status: PaymentStatus): Observable<UnknownObject>{
    return this.http.patch<UnknownObject>(`${environment.supraApi}/v2/payout/payment/${id}`, {
      status
    })
  }

  rejectTransfer(paymentId: string): Observable<UnknownObject>{
    return this.http.post<UnknownObject>(`${environment.supraApi}/v2/payout/payment/${paymentId}/reject/`, {
      "userId": "me"
    })
  }

  getPayoutDocumentById(fileId: string){
    return this.http.get(`${environment.supraApi}/v1/payout/payment/documents/${fileId}`, {
      responseType: 'blob',
      headers: {
        // Accept: 'application/pdf',
      }
    })
  }
}
